@media (min-width: 600px) {
  .errorPageContainer {
    font-family: "Roboto";
    text-align: center;
    justify-content: center;
    background-size: cover;
    height: 100%;
    min-height: 100vh;
    margin: 0px;
    background: radial-gradient(
      circle,
      rgba(27, 198, 255, 1) 0%,
      rgba(0, 153, 204, 1) 100%
    );
  }

  .errorPageAnimationContainer {
    margin: 0px;
    display: block;
    text-align: center;
  }

  .animationDiv {
    margin-bottom: 10vh;
    display: inline-block;
    text-align: center;
  }

  .errorPageButton {
    bottom: 3vw;
    padding: 15px;
    border-radius: 7px;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%);
    color: #fff;
    border: 2px solid #fff;
    background: transparent;
    font-weight: bolder;
    font-size: 1rem;
    box-shadow: 0 2.8px 2.2px rgba(0, 0, 0, 0.034),
      0 6.7px 5.3px rgba(0, 0, 0, 0.048), 0 12.5px 10px rgba(0, 0, 0, 0.06),
      0 22.3px 17.9px rgba(0, 0, 0, 0.072), 0 41.8px 33.4px rgba(0, 0, 0, 0.086),
      0 100px 80px rgba(0, 0, 0, 0.12);
    cursor: pointer;
    transition: 0.3s;
    outline: none;
    margin-bottom: 5vh;
  }

  .errorPageButton:hover {
    transform: translate(-50%, -10px);

    background: #fff;
    color: rgba(0, 153, 204, 1);
  }

  h1,
  h2,
  h3 {
    margin: 0px;
  }

  .errorPageText {
    display: inline-block;
    color: #fff;
    font-size: 1.2rem;
    margin-left: 20px;
    margin-right: 20px;
  }

  .errorPageTextContainer {
    text-align: center;
    margin-bottom: 10vh;
  }
}

@media (max-width: 600px) {
  .errorPageContainer {
    font-family: "Ubuntu";
    text-align: center;
    justify-content: center;
    background-size: cover;
    height: 100%;
    min-height: 100vh;
    margin: 0px;
    background: radial-gradient(
      circle,
      rgba(27, 198, 255, 1) 0%,
      rgba(0, 153, 204, 1) 100%
    );
  }

  .errorPageAnimationContainer {
    margin-top: 3vw;
    margin-bottom: 10vh;
    margin: 0px;
    display: block;
    text-align: center;
  }

  .animationDiv {
    display: inline-block;
    text-align: center;
  }

  .errorPageButton {
    bottom: 3vw;
    padding: 15px;
    border-radius: 7px;
    color: #fff;
    border: 2px solid #fff;
    background: transparent;
    font-weight: bolder;
    font-size: 1rem;
    box-shadow: 0 2.8px 2.2px rgba(0, 0, 0, 0.034),
      0 6.7px 5.3px rgba(0, 0, 0, 0.048), 0 12.5px 10px rgba(0, 0, 0, 0.06),
      0 22.3px 17.9px rgba(0, 0, 0, 0.072), 0 41.8px 33.4px rgba(0, 0, 0, 0.086),
      0 100px 80px rgba(0, 0, 0, 0.12);
    cursor: pointer;
    transition: 0.3s;
    outline: none;
    margin-bottom: 5vh;
  }

  .errorPageButton:hover {
    transform: translateY(-10px);
    background: #fff;
    color: rgba(0, 153, 204, 1);
  }

  h1,
  h2,
  h3 {
    margin: 0px;
  }

  .errorPageText {
    display: inline-block;
    color: #fff;
    top: 35%;
    text-align: left;
    font-size: 1.2rem;
    margin-left: 20px;
    margin-right: 20px;
  }

  .errorPageTextContainer {
    text-align: center;
    top: 35%;
    position: relative;
    margin-bottom: 10vh;
  }
}
